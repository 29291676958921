import React, {Component} from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Alert } from 'reactstrap';
export default class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:"", 
            password:""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }
    handleSubmit = (event) => {
        this.props.authUser({username:this.state.username,password:this.state.password});
        event.preventDefault();
    }
    handleChangeUsername(event) {
        this.setState({username: event.target.value });
    }
    handleChangePassword(event) {
        this.setState({password: event.target.value});
    }

    render() {
        return (
        <div className="d-flex justify-content-center mt-5" >
        
            <div className="col-lg-4 col-md-8 col-sm-10 col-xs-8">
                
                <h1 className="text-center">AWS Control Panel</h1>
                <hr/>
                <p className="text-center">Log in with your username and password</p>
                
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="username">Username</Label>
                        <Input type="text" name="username" id="username" placeholder="Your username" required defaultValue={this.state.username} onChange={this.handleChangeUsername}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Input type="password" name="password" id="password" placeholder="Your password" required defaultValue={this.state.password} onChange={this.handleChangePassword}/>
                    </FormGroup>
                    <Alert color="danger" isOpen={this.props.isBadCredentials}>
                        Incorrect username or password.
                    </Alert>
                    <Button type="submit" color="success" block>Log in</Button>
                </Form>
            </div>
        </div>

        )
    }
}
