import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ActionListComponent from './ActionListComponent';
import CustomSearchBarComponent from './CustomSearchBarComponent';
import Moment from 'react-moment';
import BadgeStateComponent from './BadgeStateComponent';
import {GET_INSTANCES_URL} from '../../api/urls';
import { ORDER_INSTANCES } from '../../constants/config'
export default class InstanceListComponent extends React.Component {

  constructor() {
    super();
    this.state = {
      instances:[],
      isLoading:false
    }
    this.handleInstances = this.handleInstances.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }
  componentDidMount(){
    fetch(GET_INSTANCES_URL)
    .then(data => (data.json()))
    .then(data => {
            this.handleInstances(this.orderManual(data))
    });
  }
  handleInstances(instances){
    this.setState({instances:instances});
  }

  orderManual(data){
    var newArray = [];
    if(data && data.length > 0){
      ORDER_INSTANCES.forEach(item => {
        var found = data.find(obj => obj.InstanceName === item);
        if(found){
          newArray.push(found);
          let index = data.findIndex(obj => obj.InstanceName === item);
          data.splice(index, 1);
        }
      });
      data.forEach(rest => {
        newArray.push(rest);
      });
      return newArray;
    }
    return data;
  }
  
  actionsFormatter = (cell, row) => <ActionListComponent row={row} handleInstances={this.handleInstances}/>;
  dateLaunchTimeFormatter=(cell,row)=> <Moment format="YYYY-MM-DD HH:mm">{row.LaunchTime}</Moment>
  dateUpTimeFormatter=(cell,row)=> row.StateCode===16 ? <Moment toNow>{row.LaunchTime}</Moment> :"N/A"
  stateFormatter=(cell,row)=> <BadgeStateComponent stateCode={row.StateCode}/>
  addressFormatter=(cell,row)=> row.PublicIpAddress? 
    <a href={`${'http://'}${row.PublicIpAddress}` } target="_blank" rel='noreferrer noopener'>{row.PublicIpAddress}</a> :
    "N/A"
  instanceIdFormatter=(cell,row)=><small>{row.InstanceId}</small>

  render() {
    const instances = this.state.instances;
    const columns = [
    {
      dataField: 'InstanceId',
      text: 'Instance Id',
      formatter: this.instanceIdFormatter,
      sort: true
    },
    {
      dataField: 'InstanceName',
      text: 'Instance Name',
      sort: true
    }, 
    {
      dataField: 'InstanceType',
      text: 'Instance Type',
      sort: true
    },
    {
      dataField: 'StateCode',
      text: 'State',
      formatter: this.stateFormatter,
      sort: true
    },
    {
      dataField: 'LaunchTime',
      text: 'Last Launch Time',
      formatter: this.dateLaunchTimeFormatter,
      sort: true
    },
    {
      dataField: 'UpTime',
      text: 'Up Time',
      formatter: this.dateUpTimeFormatter,
      sort: true
    },
    {
      dataField: 'PublicIpAddress',
      text: 'Public IP Address',
      formatter: this.addressFormatter,
      sort: true
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      formatter: this.actionsFormatter,
    }
  ];
    
    return (
      <div>
        <h1 className="text-center">AWS Instances</h1>
       <ToolkitProvider
          keyField="InstanceId"
          data={ instances }
          columns={ columns }
          bootstrap4 = {true}
          search>
          {
            props => (
              <div>
                <CustomSearchBarComponent { ...props.searchProps } reload={this.componentDidMount} />
                <hr />
                <BootstrapTable  { ...props.baseProps } />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );
  }
}