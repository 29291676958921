import React, { Component } from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import LoginContainer from './containers/LoginContainer';
import ControlPanelContainer from './containers/ControlPanelContainer';
import PrivateRoute from './components/Auth/PrivateRoute';
import {KEY_SESSION} from './constants/config'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState=()=>{
    let session = window.sessionStorage.getItem(KEY_SESSION);
    if(session && session !== ''){
      return JSON.parse(session);
    }else{
      session={
        isAuthed:false,
        isBadCredentials:false
      }
      window.sessionStorage.setItem(KEY_SESSION, JSON.stringify(session));
      return session;
    }
  }

  handleIsAuthed=(isAuthed)=>{
    this.setState({isAuthed:isAuthed});
    let session = this.state;
    session.isAuthed = isAuthed;
    window.sessionStorage.setItem(KEY_SESSION, JSON.stringify(session));
  }
  handleIsBadCredentials=(isBadCredentials)=>{
    this.setState({isBadCredentials:isBadCredentials});
    let session = this.state;
    session.isBadCredentials = isBadCredentials;
    window.sessionStorage.setItem(KEY_SESSION, JSON.stringify(session));
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route 
            exact path="/"
            component={
              ()=><LoginContainer handleIsAuthed={this.handleIsAuthed} handleIsBadCredentials={this.handleIsBadCredentials} isBadCredentials={this.state.isBadCredentials}/>
            }
          />
          <PrivateRoute exact path="/instances" component={
              ()=><ControlPanelContainer handleIsAuthed={this.handleIsAuthed} />
            } isAuthed={this.state.isAuthed} />
          <Route 
            exact path="/login"
            component={
              ()=><LoginContainer handleIsAuthed={this.handleIsAuthed} handleIsBadCredentials={this.handleIsBadCredentials} isBadCredentials={this.state.isBadCredentials}/>
            }
          />
        </Switch>
      </BrowserRouter>
    )
  }
}
export default App;
