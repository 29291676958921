import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ACTION_URL} from '../../api/urls';
 const ActionListComponent = (props)=> {
    let isLoading=false;
    const handleStartAction =()=>{
        if(!isLoading){
            handleActions('start',props.row.InstanceId);           
        }
        
    }
    const handleStopAction =()=>{
        if(!isLoading){
            handleActions('stop',props.row.InstanceId);
        }
    }
    const handleActions=(action,instanceId)=>{
        isLoading=true;
        fetch(ACTION_URL, {
          method: 'POST',
          body: JSON.stringify({'action':action,'instanceId':instanceId}),
          headers: new Headers({'Content-type': 'application/json'})
        })
        .then(data => data.json())
        .then(data =>{
          props.handleInstances(data);
          isLoading=false;
        });
    }
    const disabledStartButton =(stateCode)=>{
        return stateCode===80? false : true;
    }
    const disabledStopButton =(stateCode)=>{
        return stateCode===16? false : true;
    }
    return (
        <ButtonGroup>          
            <Button color="success" onClick={handleStartAction} disabled={disabledStartButton(props.row.StateCode)}>
                <FontAwesomeIcon icon="play" />
            </Button>
            <Button color="danger" onClick={handleStopAction} disabled={disabledStopButton(props.row.StateCode)}>
                <FontAwesomeIcon icon="stop" />
            </Button>
        </ButtonGroup>
    )
}
export default ActionListComponent;