import React from 'react';
import { Badge } from 'reactstrap';

import {AWS_EC2_STATES} from '../../constants/config';
const BadgeStateComponent = (props)=> {

    return (
      <Badge color={AWS_EC2_STATES[props.stateCode].color}>
        {AWS_EC2_STATES[props.stateCode].name}
      </Badge>
    )
}
export default BadgeStateComponent;