import React, { Component } from 'react'
import Header from '../components/Header';
import InstanceListComponent from '../components/ControlPanel/InstanceListComponent';
export default class ControlPanelContainer extends Component {
  render() {
    return (
      <React.Fragment>

        <Header handleIsAuthed={this.props.handleIsAuthed}/>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <InstanceListComponent/>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
