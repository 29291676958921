import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const  CustomSearchBarComponent = (props) => {
    let input;
    const handleSearch = () => {
      props.onSearch(input.value);
    };
    const handleReload = () => {
        props.reload();
    };
    return (
            
        <div className="input-group">
            
            <input 
                type="text" 
                className="form-control" 
                ref={ n => input = n }
                aria-describedby="button-search"/>
            <div className="input-group-append">
                <button className="btn btn-primary" onClick={ handleSearch } type="button" id="button-search">Search</button>
                <button className="btn btn-info" onClick={ handleReload } type="button">
                <FontAwesomeIcon icon="sync" /> Reload
            </button>
                
            </div>
            
        </div> 
    );
};
export default CustomSearchBarComponent;