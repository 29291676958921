export const APP_NAME= "AWS Control Panel";
export const AWS_EC2_STATES={
    0 : {"name":"Pending","color":"info"},
    16 : {"name":"Running","color":"success"},
    32 : {"name":"Shutting Down","color":"warning"},
    48 : {"name":"Terminated","color":"secondary"},
    64 : {"name":"Stopping","color":"warning"},
    80 : {"name":"Stopped","color":"danger"}
}


export const USERS=[
    {
        'username':'admin',
        'password':'sha1$3ce713b3$1$e90f6160cb3924c8ea6f6d2b69be15663e2c906d'
    }
]

export const ORDER_INSTANCES=[
    'Infonovus BDM', 
    'Infonovus IPD Demo', 
    'Infonovus Master Kubernetes', 
    'Infonovus Worker Kubernetes', 
    'Infonovus MapR Cluster', 
    'Infonovus MapR Dashboard'
];

export const KEY_SESSION= "aws.infonovus.com";
