import React, { Component } from 'react';
import LoginComponent from '../components/Auth/LoginComponent';
import {USERS} from '../constants/config';
import { withRouter } from 'react-router-dom';
let passwordHash = require('password-hash');

class LoginContainer extends Component {
  authUser = (credentials)=>{
     let isAuthed = USERS.some(user => { 
      return (passwordHash.verify(credentials.password, user.password) && credentials.username===user.username);
    })
    this.props.handleIsAuthed(isAuthed);
    if (isAuthed){
      this.props.history.push('/instances');
      this.props.handleIsBadCredentials(false);
    }else{
      this.props.handleIsBadCredentials(true);
    }
  }
  render() {
    return (
      <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <LoginComponent authUser={this.authUser} isBadCredentials={this.props.isBadCredentials}/>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(LoginContainer);