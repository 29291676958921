import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
  import {Link} from 'react-router-dom';
import {APP_NAME} from '../../constants/config';
export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  handleLogout(){
    this.props.handleIsAuthed(false);
  }
  render() {
    return (
        <Navbar color="dark" dark expand="md" className="mb-5">
          <NavbarBrand href="">{APP_NAME}</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink >Instances</NavLink>
              </NavItem>
              <NavItem>
                <Link className='nav-link' to="/login" onClick={() => this.handleLogout()}>
                    Log out
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
    );
  }
}